import { Layout, getThemedContent } from '@msanvarov/core-components';
import { en } from '@msanvarov/i18n';
import { useTheme } from 'next-themes';
import Link from 'next/link';
import { useState, useEffect } from 'react';

import { useRouter } from 'next/navigation';
import Loader from './Loader';
import dataFetch, { getArticleData, registerStreak } from '../utils/dataFetch';
import Popup from './popup';

import { icons } from './jobs';

import { useAuth, useUser } from '@clerk/nextjs';
import { setEvents, setJobs, setUser, useAppDispatch } from '@msanvarov/store';

import animationData from '../public/assets/lottie/airedify-lotti.json';

import EventComponent from './EventComponent';
import IntroComponent from './IntroComponent';
import JobComponent from './JobComponent';
import YouTubeVideos from './YouTubeVideos';
import MasterclassComponent from './MasterclassComponent';
import LastRow from './LastRow';
import AfterJobRow from './AfterJobRow';
import {
  Box,
  Grid,
  Snackbar,
  Button,
  Paper,
  Typography,
  Avatar,
  Stack,
  TextField,
  useMediaQuery,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SideMenu from 'libs/core-components/src/lib/layout/SideMenu';

import styled from 'styled-components';
import Advertisement from './advertisement';

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'; // Corporate icon
import LocationOnIcon from '@mui/icons-material/LocationOn'; // Location icon
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'; // Bookmark icon
import SearchIcon from '@mui/icons-material/Search'; // Search icon
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // Arrow icon
import EditIcon from '@mui/icons-material/Edit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Image from 'next/image';
import CertificateImage from '../public/assets/background/Certification.png';
import classThumbnail from '../public/assets/background/classThumbnail.png';
import WorkIcon from '@mui/icons-material/Work';
import EventIcon from '@mui/icons-material/Event';
import ArticleIcon from '@mui/icons-material/Article';
import CalendarComponent from '../components/CalendarComponent';
import WhatsappChannelComponent from '../components/WhatsappChannelComponent';
import PostInbox from '../components/PostInbox';
import Feeds from '../components/Feeds';
import ProfileCard from '../components/ProfileCard';
import LiveClass from '../components/LiveClass';
import Tasks from '../components/Tasks';
import Days from './days';
import Animatedad from './animatedAd';
import Animatedfeature from './animatedFeature';
import Banner1 from '../public/assets/background/banner1.png';
import Banner2 from '../public/assets/background/banner2.png';
import Banner3 from '../public/assets/background/banner3.png';
import { TutorialPopup } from '../components/Popup';
// Define the styled component with media queries
// @ts-ignore

interface AboutAreaProps {
  isSidebarCollapsed: boolean;
}
// Define the styled component with media queries
const AboutArea = styled.section<AboutAreaProps>`
  margin-left: ${(props) => (props.isSidebarCollapsed ? '0px' : '200px')};
  transition: margin-left 0.3s; /* Optional for smooth transitions */

  @media (max-width: 600px) {
    margin-left: 0px;
  }
`;

const videos = [
  'https://www.youtube.com/embed/fOpLQ-wXH4M?start=1',
  'https://www.youtube.com/embed/Lg0ZDl7V7Vk?start=131',
  'https://www.youtube.com/embed/l6UD7P1mWSU?start=16',
];
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const LandingPage = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  // const handleSidebarToggle = () => {
  //   setIsSidebarCollapsed(prevState => !prevState);
  // };

  const { theme } = useTheme();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [showPopup, setShowPopup] = useState(true);
  const [isAllowed, setAllowed] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [isUserAuthenticated, SetUserAuthenticated] = useState(false);
  const [openToast, setOpenToast] = useState(true);
  const [articleData, setArticleData] = useState();
  const router = useRouter();
  const [isSplashScreenVisible, setSplashScreenVisible] = useState(true);

  const [showIntroTutorial, setShowIntroTutorial] = useState(true);
  const { isLoaded, isSignedIn, user } = useUser();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [jobsReccomendation, setJobsReccomendation] = useState([]);
  console.log(articleData);
  const [eventsReccomendation, setEventsReccomendation] = useState([]);

  // @ts-ignore
  const handleSidebarToggle = (collapsed) => {
    setIsSidebarCollapsed(collapsed);
  };

  const tourSteps = [
    {
      title: 'Your Study Room',
      description:
        'Navigate between Roadmaps, Tutorials, Interview Questions and notes from here',
      position: {
        popup: {
          top: '15%',
          left: '13%',
        },
        triangle: {
          position: 'right',
          offset: 40,
        },
      },
    },
    {
      title: 'Track Progress',
      description:
        'Monitor your learning journey and achievements in real-time',
      position: {
        popup: {
          top: '40%',
          right: '20%',
        },
        triangle: {
          position: 'left',
          offset: 30,
        },
      },
    },
    {
      title: 'Get Started',
      description:
        'Begin your learning adventure with our comprehensive resources',
      position: {
        popup: {
          bottom: '20%',
          left: '30%',
        },
        triangle: {
          position: 'bottom',
          offset: 50,
        },
      },
    },
  ];

  console.log(user, 'this is the user');
  // @ts-ignore
  const handleRedirect = (page) => {
    router.push(`/${page}`);
  };

  useEffect(() => {
    // Add ConvertKit script
    const script = document.createElement('script');
    script.src = 'https://airedify.kit.com/0ccd85a6bb/index.js';
    script.async = true;
    script.setAttribute('data-uid', '0ccd85a6bb');
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      try {
        document.body.removeChild(script);
      } catch (err) {
        console.log('error : ', err);
      }
    };
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
      registerStreak(user?.emailAddresses[0].emailAddress);
    }

    if (user?.emailAddresses[0].emailAddress) {
      console.log('user signed up');
      setShowSignup(true);
      dispatch(setUser(user));
    }
  }, [user]);

  useEffect(() => {
    setTimeout(() => {
      setShowSignup(true);
    }, 3000);
  }, []);

  useEffect(() => {
    getArticleData('articles').then((data) => {
      setArticleData(data?.data?.articles);
    });
  });

  const setSignUpShow = () => {
    // const objectInfo = document
    //   .getElementById('sign-up-show')
    //   ?.getBoundingClientRect();

    // const viewPortHeight =
    //   document.documentElement.clientHeight || window.innerHeight;

    // // @ts-ignore
    // if (viewPortHeight - objectInfo?.top > 0) {
    //   setShowSignup(true);
    // }

    setTimeout(() => {
      setShowSignup(true);
    }, 4000);
  };

  if (isLoaded && isSplashScreenVisible) {
    setSplashScreenVisible(false);
  }

  if (isSignedIn && !isAllowed && showPopup) {
    console.log(isAllowed, 'isallowed');
    setAllowed(true);
    setShowPopup(false);
  }

  useEffect(() => {
    const sheetname = 'jobs';
    const data = dataFetch(sheetname, null);

    data.then((res) => {
      if (res?.data) {
        dispatch(setJobs(res.data));
      }

      //create a reccomendations array

      if (res?.data) {
        const temp = res.data;

        if (temp.length > 3) {
          setJobsReccomendation(
            res.data.slice(res.data.length - 3, res.data.length - 1).reverse()
          );
        } else {
          setJobsReccomendation(res.data.reverse());
        }
      }

      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const sheetname = 'events';
    const data = dataFetch(sheetname, null);

    data.then((res) => {
      if (res?.data) {
        dispatch(setEvents(res.data));
      }

      //create a reccomendations array
      if (res?.data) {
        const temp = res.data;

        if (temp.length > 3) {
          setEventsReccomendation(
            res.data?.slice(res.data.length - 3, res.data.length - 1)?.reverse()
          );
        } else if (temp.length > 0) {
          console.log(res.data);
          setEventsReccomendation(res.data);
        }
      }

      setLoading(false);
    });
  }, []);
  // @ts-ignore
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const handleStartClick = () => {
    window.open('https://chat.whatsapp.com/BtBjxESWWUG5BEFwaofIgz', '_blank');
  };

  if (isSplashScreenVisible) {
    return <Loader />; // Show Loader component
  }

  // {showIntroTutorial && (
  //   <TutorialPopup
  //     className=""
  //       // @ts-ignore
  //     steps={tourSteps}
  //     onClose={() => setShowIntroTutorial(false)}
  //   />
  // )}

  return (
    <Layout
      setShowSignup={setShowSignup}
      isSidebarCollapsed={isSidebarCollapsed}
    >
      <SideMenu onToggleCollapse={handleSidebarToggle} />
      {showPopup && showSignup && <Popup onClose={() => setShowPopup(false)} />}
      {/* // @ts-ignore */}
      <AboutArea isSidebarCollapsed={isSidebarCollapsed} className="about-area">
        <div id="convertkit-form"></div>
        <Box
          sx={{
            width: '100%',
            padding: { xs: 2, sm: 3, md: 4 },
            px: { xs: 2, sm: 3, md: 10 },
            boxSizing: 'border-box',
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/* Events component   */}
              <Days />
              {/* <Animatedfeature /> */}

              <Animatedad />
              <Advertisement />
              {/* Add three containers with images at the top */}
              <Box sx={{ marginTop: '20px' }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Box
                      sx={{
                        borderRadius: '8px',
                        transition: 'box-shadow 0.3s ease',
                        cursor: 'pointer',
                        position: 'relative', // For Next/Image to work properly
                      }}
                      onClick={() => handleRedirect('notes')}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.boxShadow =
                          '0 4px 10px rgba(0, 0, 0, 0.3)')
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.boxShadow = 'none')
                      }
                    >
                      <Image
                        src={Banner1}
                        width={isSmallScreen ? 250 : 850} // Use the variable here
                        height={isSmallScreen ? 100 : 150} // Use the variable here
                        alt="First Image"
                        objectFit="cover"
                        className="rounded-lg"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box
                      sx={{
                        borderRadius: '8px',
                        position: 'relative',
                        transition: 'box-shadow 0.3s ease',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleRedirect('jobs')}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.boxShadow =
                          '0 4px 10px rgba(0, 0, 0, 0.3)')
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.boxShadow = 'none')
                      }
                    >
                      <Image
                        src={Banner2}
                        width={isSmallScreen ? 250 : 850} // Use the variable here
                        height={isSmallScreen ? 100 : 150} // Use the variable here
                        alt="Second Image"
                        objectFit="cover"
                        className="rounded-lg"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box
                      sx={{
                        borderRadius: '8px',
                        transition: 'box-shadow 0.3s ease',
                        cursor: 'pointer',
                        position: 'relative',
                      }}
                      onClick={() => handleRedirect('classes')}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.boxShadow =
                          '0 4px 10px rgba(0, 0, 0, 0.3)')
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.boxShadow = 'none')
                      }
                    >
                      <Image
                        src={Banner3}
                        width={isSmallScreen ? 250 : 850} // Use the variable here
                        height={isSmallScreen ? 100 : 150} // Use the variable here
                        alt="Third Image"
                        objectFit="cover"
                        className="rounded-lg"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* Event component ends */}
            </Grid>
            {/* Left Column */}
            <Grid item xs={12} md={3}>
              <div
                className="min-w-[330px]"
                style={{ transform: 'scale(0.8)', transformOrigin: 'top left' }}
              >
                <CalendarComponent />
              </div>

              <WhatsappChannelComponent />
            </Grid>

            {/* Middle Column */}
            <Grid item xs={12} md={6}>
              <PostInbox />
              {/* ARTICLES HERE */}
              <Feeds />
            </Grid>

            {/* Right Column */}
            <Grid item xs={12} md={3}>
              <Tasks />
              <ProfileCard />

              <LiveClass />
            </Grid>
          </Grid>

          {/* //old home page part */}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* Events component   */}

              <EventComponent
                loading={loading}
                eventsReccomendation={eventsReccomendation}
                icons={icons}
                router={router}
              />
              {/* Event component ends */}
            </Grid>

            <Grid item xs={12}>
              <IntroComponent
                // @ts-ignore
                animationData={animationData}
                en={en}
                theme={theme}
                getThemedContent={getThemedContent}
              />
            </Grid>

            <Grid item xs={12}>
              <JobComponent
                jobsReccomendation={jobsReccomendation}
                icons={icons}
              />
            </Grid>

            {/* <Grid item xs={12} mt={4}>
              <AfterJobRow />
            </Grid> */}

            <Grid item xs={12} mt={4}>
              <MasterclassComponent />
            </Grid>

            <Grid item xs={12} mt={4}>
              <YouTubeVideos videos={videos} />
            </Grid>

            <Grid item xs={12} mt={4}>
              <LastRow />
            </Grid>
          </Grid>
        </Box>
      </AboutArea>
    </Layout>
  );
};

export default LandingPage;
